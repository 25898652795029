

.footer{
    position: fixed;
    bottom: 0px;
    border-top: 5px solid #a6c529;
    width: 100%;
  }

  .copyright{
    font-size: medium;
  }