/* @font-face kit by Fonts2u (https://fonts2u.com) */ 
@font-face {
  font-family:"Bellerose Light:1.0";
  src: url("Bellerose.woff") format("woff");
  font-weight:normal;
  font-style:normal;
}

/* Logo */


#NavlogoNameL, #NavlogoNameR{
  font-family: "Bellerose Light:1.0";
  font-size: 45px;
  vertical-align: text-top;
}

#NavlogoNameL{
  color: #a6c529;
  margin-left: -6px
}

#NavlogoNameR{
  color: #858689
}






