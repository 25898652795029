.navbar{
    padding: 0.5rem 2rem;
}

.NavLogoWrapper{
    width: 104px;
    height:auto;
}


.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link{
    color: #c386c3;
}