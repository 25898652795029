/* @font-face kit by Fonts2u (https://fonts2u.com) */ 
@font-face {
  font-family:"Bellerose Light:1.0";
  src: url("Bellerose.woff") format("woff");
  font-weight:normal;
  font-style:normal;
}

/* Logo */
.Logocontainer{
  width: 66%;
  padding:0vw;
}

.LogoSymbolContainer{
  position: relative;

}


.logoName{
  position: absolute;
  top: 70%;
  left:49%;
  text-align: center;
  transform: translate(-50%, -50%);
  white-space:nowrap;
}

#logoNameL, #logoNameR{
  font-family: "Bellerose Light:1.0";

  font-size: 45px;


}

#logoNameL{

  color: #a6c529
}

#logoNameR{

  color: #858689
}

.el{
  display:inline-block;
}




