body{
    background-image: url(../assets/images/background/random_grey_variations_@2X.png);
}


  
  .card1{
    height:min-content;
    background-color: white;
    min-height: 550px;
  }
  
  a:link, a:visited, a:hover, a:active{
    text-decoration: none;
  }



  .card{
    position: relative;
  }





