.aboutCard .card{
    border-radius: 2rem;
    border:none;


}

.aboutCard img{
    border-radius: 2rem 2rem 0rem 0rem;
}

@media (min-width: 767px) {
    .aboutCard img{
        border-radius: 2rem 0rem 0rem 2rem;
        width: auto;
        object-fit: cover;
    }; 
}

.aboutImgContainer{
    display: flex;
}