.contactCard{
    height:min-content;
    background-color: white;
    min-height: 280px;
    padding: 15px;
    border-radius: 2rem
  }

.phone, .email {
    padding: 2px 0px 0px 0px;
    font-size: 12px;
    color: black
  }

  .phone i, .email i {
    padding: 0px 4px 0px 0px;

  }
  
.phone a, .email a{
    color: black
  }

.contactTop{
    position: relative;
}

#github {
    color: black
  }

#contactCard > .header{
    display: inline;
}

.header{
    color: #858689;
    border-bottom: 1px solid #858689;
}

#contact h2{
    margin-bottom: -5px;
}

#resume{
    padding-bottom: 15px;
    font-size: 15px;
}

#resume a{
    color: #a6c529;
    font-weight: bolder;
}

#social{
    position: absolute;
    bottom: 5px;
    right: 0px;
    font-size: 10px;
    display: inline;
  }

#contactCard{
    max-width: 1000px;
    min-width: 385px;
  }

#contact{
      padding: 1rem
  }

#logo{
  padding: 1rem
}